<template>
  <div>
    <v-row>
      <v-col cols="12">
        <TopTitle :title="$_t('attribute.globalReports')" />
        <v-btn
          outlined
          color="primary"
          width="150px"
          class="py-5 float-end ml-3"
          @click="downloadPDF"
        >
          {{ $_t("attribute.downloadPDF") }}
          <v-icon class="pl-3">mdi-printer</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <ReportTotalTeachingHour
          v-if="['Admin', 'FinancialAdmin', 'TeamLeader'].includes(authRole)"
          ref="totalTeaching"
        />
      </v-col>
      <v-col cols="12">
        <ReportAllStudentAvgExamScores
          v-if="!['Teacher', 'FinancialAdmin'].includes(authRole)"
          ref="RAE"
        />
      </v-col>
      <v-col cols="12">
        <ReportTeachingProgress
          ref="RTP"
          :public-key="user.role === 'Teacher' ? user.publicKey : null"
        />
      </v-col>
      <v-col cols="12">
        <ReportReviewProgress
          ref="RRP"
          :public-key="user.role === 'Teacher' ? user.publicKey : null"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ReportAllStudentAvgExamScores from "@/packages/admin/components/reports/ReportAllStudentAvgExamScores";
import ReportTeachingProgress from "@/packages/admin/components/reports/ReportTeachingProgress";
import ReportReviewProgress from "@/packages/admin/components/reports/ReportReviewProgress";
import TopTitle from "@/components/app/TopTitle";
import { mapGetters } from "vuex";
import { jsPDF } from "jspdf";
import ReportTotalTeachingHour from "@/packages/admin/components/reports/totalTeaching/ReportTotalTeachingHour";
export default {
  name: "reports",
  components: {
    ReportTotalTeachingHour,
    TopTitle,
    ReportReviewProgress,
    ReportTeachingProgress,
    ReportAllStudentAvgExamScores,
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
      authRole: "authentication/role",
    }),
  },
  data() {
    return {
      menu: false,
      date: "",
    };
  },
  methods: {
    async downloadPDF() {
      const { imgURI1, imgURI2 } = await this.$refs.RTP.downloadURI();
      const { imgURI3 } = await this.$refs.RRP.downloadURI();
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      // pdf.addFont("NotoSansCJKjp-Regular.ttf", "NotoSansCJKjp", "normal");
      // pdf.setFont("NotoSansCJKjp");
      const {
        eachLessonTotalTeaching,
        eachLevelTotalTeaching,
        totalTeachingHour,
      } = await this.$refs.totalTeaching.getChartImg();
      if (totalTeachingHour) {
        pdf.text(this.$_t("attribute.totalTeachingHour"), 10, 10);
        pdf.addImage(totalTeachingHour, "PNG", 0, 15);
      }
      if (eachLevelTotalTeaching) {
        pdf.addPage();
        pdf.text(this.$_t("attribute.totalTeachingHourEachLevel"), 10, 10);
        pdf.addImage(eachLevelTotalTeaching, "PNG", 0, 15);
      }
      if (eachLessonTotalTeaching) {
        pdf.addPage();
        pdf.text(this.$_t("attribute.totalTeachingHourEachLesson"), 10, 10);
        pdf.addImage(eachLessonTotalTeaching, "PNG", 0, 15);
      }

      if (this.user.role !== "Teacher") {
        const { RAEUri } = await this.$refs.RAE.downloadURI();
        pdf.addPage();
        pdf.text(this.$_t("attribute.averageOfAllStudentsExamScore"), 10, 10);
        pdf.addImage(RAEUri, "PNG", 0, 15);
      }

      pdf.addPage();
      pdf.text(this.$_t("attribute.lessonTeachingProgress"), 10, 10);
      pdf.addImage(imgURI1, "PNG", 0, 15);

      pdf.addPage();
      pdf.text(this.$_t("attribute.lessonTitleTeachingProgress"), 10, 10);
      pdf.addImage(imgURI2, "PNG", 0, 15);

      pdf.addPage();
      pdf.text(this.$_t("attribute.lessonReviewProgress"), 10, 10);
      pdf.addImage(imgURI3, "PNG", 0, 15);

      // pdf.addPage();
      // pdf.text(this.$_t("attribute.lessonTitleReviewProgress"), 10, 10);
      // pdf.addImage(imgURI4, "PNG", 0, 15);

      pdf.save(`global report ${this.$_date(new Date(), "long")}.pdf`);
    },
  },
  async created() {
    await this.$store.dispatch("class/getGlobalReportClasses", { all: true });

    if (["Teacher", "FinancialAdmin"].includes(this.authRole)) return;
    const { lessons } = await this.$actions.getLessons({ all: true });
    await this.$store.dispatch("admin/setLessons", lessons);
  },
};
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
