<template>
  <v-row>
    <v-col cols="12" v-if="series.length">
      <apexchart
        ref="eachLevelTotalTeaching"
        :options="options"
        :series="series"
        @click="getLevel"
      ></apexchart>
    </v-col>
    <v-col v-else class="d-flex justify-center">
      <v-card-subtitle class="text-center">
        {{ $_t("attribute.selectMonth") }}
      </v-card-subtitle>
    </v-col>
    <v-col cols="12" v-if="month">
      <ReportEachLessonTotalTeachingHour ref="lessonTotalTeach" />
    </v-col>
  </v-row>
</template>

<script>
import { _t } from "@/tools/Utils";
import { mapGetters } from "vuex";
import ReportEachLessonTotalTeachingHour from "@/packages/admin/components/reports/totalTeaching/ReportEachLessonTotalTeachingHour";

export default {
  name: "ReportEachLevelTotalTeaching",
  components: { ReportEachLessonTotalTeachingHour },
  data() {
    return {
      series: [],
      options: {},
      levels: [],
    };
  },
  computed: {
    ...mapGetters({
      year: "report/year",
      month: "report/month",
    }),
  },
  methods: {
    async init() {
      this.series = [];
      const { TeachingHour, Level } =
        await this.$actions.getEachLevelTotalTeachingHour({
          year: this.year,
          month: this.month,
        });
      this.levels = Level;
      this.options = {
        chart: {
          id: "ReportAllStudentAvgExamScores",
          type: "bar",
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "top",
        },
        tooltip: {
          y: {
            formatter: (seriesName) => seriesName + " h",
          },
        },
        dataLabels: {
          formatter: function (val) {
            return val + " h";
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              legend: {
                show: true,
                position: "top",
              },
            },
          },
        ],
        labels: Level.map((l) => this.$_t(`attribute.${l}`)),
      };
      this.series.push({
        name: _t("attribute.level_total_teaching_report"),
        data: [...TeachingHour],
      });
    },
    clearChart() {
      this.series = [];
      this.options = {};
      if (this.$refs.lessonTotalTeach) this.$refs.lessonTotalTeach.clearChart();
    },
    async getLevel(event, chartContext, config) {
      const level = this.levels[config.dataPointIndex];
      if (!level) return;
      await this.$store.commit("report/updateLevel", level);
      await this.$refs.lessonTotalTeach.init();
    },
    async getChartImg() {
      const img = (await this.$refs?.lessonTotalTeach?.getChartImg()) || {};
      const { imgURI } =
        (await this.$refs.eachLevelTotalTeaching?.chart.dataURI()) || {
          imgURI: null,
        };
      return { eachLevelTotalTeaching: imgURI, ...img };
    },
  },
};
</script>

<style scoped></style>
