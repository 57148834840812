<template>
  <div>
    <ChartContainer
      :loading="loading"
      :type="type"
      @input="init"
      @from="init"
      @to="init"
      show-from-to
      show-lessons
    >
      <template v-slot:title>
        <span>{{
          $_t("admin.components.charts.avg_students_exam_score")
        }}</span></template
      >
      <template v-slot:default>
        <v-card-title>
          <v-row class="justify-center">
            <v-col cols="12" v-if="series.length">
              <apexchart
                ref="AllStudentAvgExam"
                type="bar"
                :options="options"
                :series="series"
              ></apexchart>
            </v-col>
            <v-col v-else class="text-center">
              {{ $_t("admin.components.charts.no_data") }}
            </v-col>
          </v-row>
        </v-card-title>
      </template>
    </ChartContainer>
  </div>
</template>

<script>
import ChartContainer from "@/packages/Reports/components/charts/ChartContainer";
import { mapGetters } from "vuex";
import { _t } from "@/tools/Utils";
export default {
  name: "ReportAllStudentAvgExamScores",
  components: { ChartContainer },
  data() {
    return {
      type: "SubTitleExam",
      series: [],
      options: {},
      to: null,
      from: null,
      lessonName: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  methods: {
    async downloadURI() {
      const { imgURI } = await this.$refs.AllStudentAvgExam.chart.dataURI();
      return { RAEUri: imgURI };
    },
    async init({
      type = this.type,
      to = this.to,
      from = this.from,
      lessonName = this.lessonName,
    }) {
      this.series = [];
      this.type = type;
      this.to = to;
      this.from = from;
      this.lessonName = lessonName;
      const { ExamsName, ExamsScoreAvg } =
        await this.$actions.GetAllStudentsAvgExamScore(
          type,
          from,
          to,
          lessonName
        );
      this.options = {
        chart: {
          id: "ReportAllStudentAvgExamScores",
          type: "bar",
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            let name =
              seriesName.length > 20
                ? seriesName.slice(0, 20) + "..."
                : seriesName;
            return (
              name + ":  " + opts.w.globals.series[opts.seriesIndex] + " %"
            );
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              legend: {
                show: true,
                position: "top",
              },
            },
          },
        ],
        // stroke: {
        //   lineCap: "round",
        // },
        labels: [...ExamsName],
      };
      this.series.push({
        name: _t("attribute.avg_all_students_exam_score"),
        data: [...ExamsScoreAvg],
      });
      // this.series = [...ExamsScoreAvg];
      // this.series.push({
      //   name: "All student average scores",
      //   data: [...ExamsScoreAvg],
      // });
    },
  },
  created() {
    this.init({});
  },
};
</script>

<style>
.apexcharts-canvas {
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>
