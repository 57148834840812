<template>
  <ChartContainer
    :loading="loading"
    :showType="false"
    show-classes
    @input="init"
  >
    <template v-slot:title>
      <span>{{
        $_t("admin.components.charts.teaching_progress_report")
      }}</span></template
    >
    <template v-slot:default>
      <v-container fluid>
        <v-row class="justify-center">
          <v-col cols="12" v-if="LessonsNameSeries.length">
            <apexchart
              ref="teachingProgress1"
              :options="options1"
              :series="LessonsNameSeries"
            ></apexchart>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" v-if="LessonsTitleSeries.length">
            <apexchart
              ref="teachingProgress2"
              :options="options2"
              :series="LessonsTitleSeries"
            ></apexchart>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </ChartContainer>
</template>

<script>
import ChartContainer from "@/packages/Reports/components/charts/ChartContainer";
import { mapGetters } from "vuex";
import { _t } from "@/tools/Utils";

export default {
  name: "ReportTeachingProgress",
  components: { ChartContainer },
  data() {
    return {
      LessonsNameSeries: [],
      LessonsTitleSeries: [],
      options1: {},
      options2: {},
      lessonName: null,
      classroomId: null,
    };
  },
  props: {
    publicKey: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  methods: {
    async downloadURI() {
      const { imgURI: imgURI1 } =
        (await this.$refs.teachingProgress1?.chart?.dataURI()) || {
          imgURI: null,
        };
      const { imgURI: imgURI2 } =
        (await this.$refs.teachingProgress2?.chart?.dataURI()) || {
          imgURI: null,
        };
      return { imgURI1, imgURI2 };
    },
    async init({
      lessonName = this.lessonName,
      classroomId = this.classroomId,
    }) {
      this.LessonsTitleSeries = [];
      this.LessonsNameSeries = [];

      this.lessonName = lessonName;
      this.classroomId = classroomId;
      const { data } = await this.$actions.getTeachingProgress(
        this.$route.params.key || this.publicKey || null,
        "name",
        lessonName,
        classroomId
      );
      this.options1 = {
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "top",
        },
        chart: {
          type: "bar",
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 9000,
            options: {
              options: {
                plotOptions: {
                  bar: {
                    horizontal: false,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          },
        ],
        colors: [
          function ({ dataPointIndex, seriesIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            if (!data) return;
            if (data.teachingDelay !== 0) {
              return "#D9534F";
            } else {
              return "#008FFB";
            }
          },
        ],
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            return (
              "<ul class='pa-2'>" +
              `<li><b>${_t("attribute.lesson_name")}</b>: ` +
              data.x +
              "</li>" +
              `<li><b>${_t("attribute.teaching_progress_percentage")}</b>: ` +
              data.y +
              "</li>" +
              `<li><b>${_t("attribute.specified_time_teach_lesson")}</b>: ` +
              data.specifiedTimeToTeachLessonInHour +
              " h" +
              "</li>" +
              "</li>" +
              `<li><b>${_t("attribute.teaching_time_left")}</b>: ` +
              data.teachingTimeLeftInHour +
              " h" +
              "</li>" +
              `<li><b>${_t("attribute.teaching_delay")}</b>: ` +
              data.teachingDelay +
              " h" +
              "</li>" +
              `<li><b>${_t("attribute.total_teach_time")}</b>: ` +
              data.totalTeachedTime +
              " h" +
              "</li>" +
              "</ul>"
            );
          },
        },
        xaxis: {
          type: "category",
        },
      };
      this.LessonsNameSeries.push({
        name: _t("attribute.lessons_name_teaching_progress"),
        data: data.TeachingProgressDetails.map((item) => {
          return {
            ...item,
            x: item.lessonName,
            y: Math.floor(item.percentageOfTeachingProgress),
          };
        }),
      });
      //------------------------------------------------
      const { data: data2 } = await this.$actions.getTeachingProgress(
        this.$route.params.key || this.publicKey || null,
        "title",
        lessonName,
        classroomId
      );
      this.options2 = {
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "top",
        },
        chart: {
          type: "bar",
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 9000,
            options: {
              options: {
                plotOptions: {
                  bar: {
                    horizontal: false,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          },
        ],
        colors: [
          function ({ dataPointIndex, seriesIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            if (!data) return;
            if (data.teachingDelay !== 0) {
              return "#D9534F";
            } else {
              return "#008FFB";
            }
          },
        ],
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex, w }) {
            // console.log(series);
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            return (
              "<ul class='pa-2'>" +
              `<li><b>${_t("attribute.lesson")}</b>: ` +
              data.from +
              "</li>" +
              `<li><b>${_t("attribute.lesson_title")}</b>: ` +
              data.x +
              "</li>" +
              `<li><b>${_t("attribute.teaching_progress_percentage")}</b>: ` +
              data.y +
              "</li>" +
              `<li><b>${_t("attribute.specified_time_teach_lesson")}</b>: ` +
              data.specifiedTimeToTeachLessonInHour +
              " h" +
              "</li>" +
              "</li>" +
              `<li><b>${_t("attribute.teaching_left")}</b>: ` +
              data.teachingTimeLeftInHour +
              " h" +
              "</li>" +
              `<li><b>${_t("attribute.teaching_delay")}</b>: ` +
              data.teachingDelay +
              " h" +
              "</li>" +
              `<li><b>${_t("attribute.total_teach_time")}</b>: ` +
              data.totalTeachedTime +
              " h" +
              "</li>" +
              "</ul>"
            );
          },
        },
        xaxis: {
          type: "category",
        },
      };
      this.LessonsTitleSeries.push({
        name: _t("attribute.lessons_teaching_progress"),
        data: data2.TeachingProgressDetails.map((item) => {
          return {
            ...item,
            x: item.lessonTitle,
            y: Math.floor(item.percentageOfTeachingProgress),
          };
        }),
      });
    },
  },
  created() {
    // this.init({});
  },
};
</script>

<style scoped></style>
