<template>
  <div>
    <div v-if="series.length">
      <apexchart
        ref="eachLessonTotalTeaching"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
    <div v-else class="d-flex flex-fill justify-center">
      <v-card-subtitle class="text-center">
        {{ $_t("attribute.selectLevel") }}
      </v-card-subtitle>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { _t } from "@/tools/Utils";

export default {
  name: "ReportEachLessonTotalTeachingHour",
  data() {
    return {
      options: {},
      series: [],
    };
  },
  computed: {
    ...mapGetters({
      year: "report/year",
      month: "report/month",
      level: "report/level",
    }),
  },
  methods: {
    async init() {
      this.series = [];
      const { TeachingHour, Lesson } =
        await this.$actions.getEachLessonTotalTeachingHour({
          level: this.level,
          year: this.year,
          month: this.month,
        });
      this.options = {
        chart: {
          id: "ReportAllStudentAvgExamScores",
          type: "bar",
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "top",
        },
        tooltip: {
          y: {
            formatter: (seriesName) => seriesName + " h",
          },
        },
        dataLabels: {
          formatter: function (val) {
            return val + " h";
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              legend: {
                show: true,
                position: "top",
              },
            },
          },
        ],
        labels: Lesson.map((l) => this.$_t(`attribute.${l}`)),
      };
      this.series.push({
        name: _t("attribute.lesson_total_teaching_report"),
        data: [...TeachingHour],
      });
    },
    clearChart() {
      this.series = [];
      this.options = {};
    },
    async getChartImg() {
      const { imgURI } =
        (await this.$refs.eachLessonTotalTeaching?.chart.dataURI()) || {
          imgURI: null,
        };
      return { eachLessonTotalTeaching: imgURI };
    },
  },
};
</script>

<style scoped></style>
