<template>
  <v-select
    hide-details
    dense
    outlined
    v-bind="$attrs"
    :value="type"
    @input="$emit('input', $event)"
    :items="items"
  />
</template>

<script>
import { examTypes } from "@/packages/Reports/schema/EXAM_TYPE.schema";

export default {
  name: "ExamTypeSelect",
  props:{
    type: {
      required: true,
      default: "Total",
    }
  },
  data() {
    return {
      items: examTypes,
    };
  },
};
</script>

<style scoped></style>
