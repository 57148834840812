<template>
  <div>
    <ChartContainer :show-type="false" :loading="loading">
      <template v-slot:title>
        <span>{{ $_t("admin.components.charts.total_teaching_report") }}</span>
        <v-spacer />
      </template>
      <template v-slot:extra>
        <v-row>
          <v-spacer />
          <v-col cols="3">
            <v-select
              :label="$_t('attribute.year')"
              :items="years"
              outlined
              dense
              v-model="year"
              @input="change"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:default>
        <v-container fluid>
          <v-row class="justify-center">
            <v-col cols="12" v-if="series.length">
              <apexchart
                ref="totalTeachingHour"
                type="bar"
                :options="options"
                :series="series"
                @click="getTotalTeachHour"
              ></apexchart>
            </v-col>
            <v-col v-else class="text-center">
              {{ $_t("admin.components.charts.no_data") }}
            </v-col>
          </v-row>
          <ReportEachLevelTotalTeaching ref="levelTotalTeach" />
        </v-container>
      </template>
    </ChartContainer>
  </div>
</template>

<script>
import ChartContainer from "@/packages/Reports/components/charts/ChartContainer";
import { mapGetters } from "vuex";
import { _t } from "@/tools/Utils";
import ReportEachLevelTotalTeaching from "@/packages/admin/components/reports/totalTeaching/ReportEachLevelTotalTeaching";
import { years } from "@/packages/admin/schema/YEARS";
export default {
  name: "ReportTotalTeachingHour",
  components: { ReportEachLevelTotalTeaching, ChartContainer },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
    year: {
      get() {
        return this.$store.getters["report/year"];
      },
      set(value) {
        this.$store.commit("report/updateYear", value);
      },
    },
  },
  data() {
    return {
      series: [],
      options: {},
      years,
    };
  },
  methods: {
    async init() {
      this.series = [];
      const { Month, TeachingHour } =
        await this.$actions.getTotalTeachingHourReport({
          year: this.year,
        });
      this.options = {
        chart: {
          id: "ReportAllStudentAvgExamScores",
          type: "bar",
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "top",
        },
        tooltip: {
          y: {
            formatter: (seriesName) => seriesName + " h",
          },
        },
        dataLabels: {
          formatter: function (val) {
            if (val > 0) return val + " h";
            return val;
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              legend: {
                show: true,
                position: "top",
              },
            },
          },
        ],
        labels: [...Month],
      };
      this.series.push({
        name: _t("attribute.total_teaching_report"),
        data: [...TeachingHour],
      });
    },
    async change() {
      if (this.$refs.levelTotalTeach) this.$refs.levelTotalTeach.clearChart();
      await this.$store.commit("report/updateMonth", null);
      await this.$store.commit("report/updateLevel", null);
      await this.init();
    },
    async getTotalTeachHour(event, chartContext, config) {
      const month = config.config.labels[config.dataPointIndex];
      if (!month) return;
      await this.$store.commit("report/updateMonth", config.dataPointIndex + 1);
      await this.$refs.levelTotalTeach.init();
    },
    async getChartImg() {
      //totalTeachingHour
      const img = (await this.$refs.levelTotalTeach.getChartImg()) || {};
      const { imgURI } =
        (await this.$refs.totalTeachingHour.chart.dataURI()) || {
          imgURI: null,
        };
      return { totalTeachingHour: imgURI, ...img };
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped></style>
