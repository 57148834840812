<template>
  <ChartContainer
    :loading="loading"
    :showType="false"
    show-classes
    @input="init"
  >
    <template v-slot:title>
      <span>{{
        $_t("admin.components.charts.review_progress_report")
      }}</span></template
    >
    <template v-slot:default>
      <v-container fluid>
        <v-row class="justify-center">
          <v-col cols="12" v-if="LessonsNameSeries.length">
            <apexchart
              ref="reviewProgress1"
              :options="options1"
              :series="LessonsNameSeries"
            ></apexchart>
          </v-col>
        </v-row>
        <!--        <v-row class="justify-center">-->
        <!--          <v-col cols="12" v-if="LessonsTitleSeries.length">-->
        <!--            <apexchart-->
        <!--              ref="reviewProgress2"-->
        <!--              :options="options2"-->
        <!--              :series="LessonsTitleSeries"-->
        <!--            ></apexchart>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-container>
    </template>
  </ChartContainer>
</template>

<script>
import ChartContainer from "@/packages/Reports/components/charts/ChartContainer";
import { mapGetters } from "vuex";
import { _t } from "@/tools/Utils";
export default {
  name: "ReportReviewProgress",
  components: { ChartContainer },
  data() {
    return {
      LessonsNameSeries: [],
      LessonsTitleSeries: [],
      options1: {},
      options2: {},
      lessonName: null,
      classroomId: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  props: {
    publicKey: {
      type: String,
      default: null,
    },
  },
  methods: {
    async downloadURI() {
      const { imgURI: imgURI3 } =
        (await this.$refs.reviewProgress1?.chart?.dataURI()) || {
          imgURI: null,
        };
      // const { imgURI: imgURI4 } =
      //   (await this.$refs.reviewProgress2?.chart?.dataURI()) || {
      //     imgURI: null,
      //   };
      return { imgURI3, imgURI4: null };
    },
    async init({
      lessonName = this.lessonName,
      classroomId = this.classroomId,
    }) {
      this.LessonsNameSeries = [];
      // this.LessonsTitleSeries = [];

      this.lessonName = lessonName;
      this.classroomId = classroomId;
      const { data } = await this.$actions.getReviewProgress(
        this.$route.params.key || this.publicKey || null,
        "name",
        lessonName,
        classroomId
      );
      this.options1 = {
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "top",
        },
        chart: {
          type: "bar",
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 9000,
            options: {
              options: {
                plotOptions: {
                  bar: {
                    horizontal: false,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          },
        ],
        // xaxis: {
        //   type: "category",
        // },
        xaxis: {
          categories: [...data.LessonsName],
        },
      };
      this.LessonsNameSeries.push({
        name: _t("attribute.lessons_name_review_progress"),
        data: data.PercentageOfReviewProgress.map((i) => Math.floor(i)),
      });
      // const { data: data2 } = await this.$actions.getReviewProgress(
      //   this.$route.params.key || this.publicKey || null,
      //   "title",
      //   lessonName,
      //   classroomId
      // );
      // this.options2 = {
      //   dataLabels: {
      //     enabled: true,
      //   },
      //   legend: {
      //     show: true,
      //     showForSingleSeries: true,
      //     position: "top",
      //   },
      //   chart: {
      //     type: "bar",
      //   },
      //   responsive: [
      //     {
      //       breakpoint: 1000,
      //       options: {
      //         plotOptions: {
      //           bar: {
      //             horizontal: true,
      //           },
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //     {
      //       breakpoint: 9000,
      //       options: {
      //         options: {
      //           plotOptions: {
      //             bar: {
      //               horizontal: false,
      //             },
      //           },
      //           legend: {
      //             position: "bottom",
      //           },
      //         },
      //       },
      //     },
      //   ],
      //   tooltip: {
      //     custom: function ({ seriesIndex, dataPointIndex, w }) {
      //       // console.log(series);
      //       var data =
      //         w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      //
      //       return (
      //         "<ul class='pa-2'>" +
      //         `<li><b>${_t("attribute.lesson")}</b>: ` +
      //         data.from +
      //         "</li>" +
      //         `<li><b>${_t("attribute.lesson_title")}</b>: ` +
      //         data.x +
      //         "</li>" +
      //         `<li><b>${_t("attribute.review_progress_percentage")}</b>: ` +
      //         data.y +
      //         "</li>" +
      //         "</ul>"
      //       );
      //     },
      //   },
      //   xaxis: {
      //     type: "category",
      //   },
      // };

      // this.LessonsTitleSeries.push({
      //   name: _t("attribute.lessons_review_progress"),
      //   data: data2.ReviewProgressDetails.map((item) => {
      //     return {
      //       ...item,
      //       x: item.lessonTitle,
      //       y: Math.floor(item.percentageOfReviewProgress),
      //     };
      //   }),
      // });
    },
  },
  created() {
    // this.init({});
  },
};
</script>

<style scoped></style>
