<template>
  <v-card
    dark
    height="100%"
    :min-height="minHeight"
    :loading="loading"
    color="#2B2D3E"
  >
    <template v-slot:progress>
      <v-progress-linear
        color="#D7DA40"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-card-title>
      <v-row class="d-flex flex-column flex-md-row justify-space-between">
        <v-col cols="12" md="4">
          <slot name="title" class="pl-10 font-weight-bold" />
        </v-col>
        <v-col v-if="$slots.extra" cols="12" md="8">
          <slot name="extra"></slot>
        </v-col>
        <v-col v-if="showFromTo">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="from"
                clearable
                :label="$_t('attribute.from')"
                outlined
                dense
                readonly
                hide-details
                v-bind="attrs"
                @click:clear="$emit('from', { from: null })"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              color="primary"
              v-model="from"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu1 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu1.save(from);
                  $emit('from', { from });
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col v-if="showFromTo">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="to"
                clearable
                :label="$_t('attribute.to')"
                hide-details
                outlined
                dense
                readonly
                v-bind="attrs"
                @click:clear="$emit('to', { to: null })"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              color="primary"
              v-model="to"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu2.save(to);
                  $emit('to', { to });
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" v-if="showExport">
          <v-btn block color="primary" @click="$emit('export')">{{
            $_t("attribute.export")
          }}</v-btn>
        </v-col>
        <v-col v-if="showType">
          <ExamTypeSelect
            :type="type"
            label="Exam type"
            @input="$emit('input', { type: $event })"
          />
        </v-col>
        <v-col :cols="showType ? 3 : 4" v-if="showClasses">
          <v-select
            dense
            hide-details
            outlined
            :label="$_t('attribute.classroom')"
            @input="$emit('input', { classroomId: $event })"
            :items="classes"
            item-text="classroomName"
            item-value="classroomId"
            clearable
          >
            <template v-slot:prepend-item>
              <div class="px-3 py-2">
                <v-text-field
                  outlined
                  :label="$_t('attribute.search')"
                  dense
                  hide-details
                  @input="searchInClasses({ text: $event })"
                />
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <slot name="default"></slot>
  </v-card>
</template>

<script>
import ExamTypeSelect from "@/packages/Reports/components/ExamTypeSelect";
import { mapGetters } from "vuex";
export default {
  name: "ChartContainer",
  components: { ExamTypeSelect },
  computed: {
    ...mapGetters({
      classes: "class/classrooms",
    }),
  },
  props: {
    type: {
      type: String,
      default: "Total",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showFromTo: {
      type: Boolean,
      default: false,
    },
    showType: {
      type: Boolean,
      default: true,
    },
    showLessons: {
      type: Boolean,
      default: false,
    },
    showClasses: {
      type: Boolean,
      default: false,
    },
    showExport: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      from: null,
      to: null,
    };
  },
  methods: {
    async searchInClasses({ text = null }) {
      await this.$store.dispatch("class/getGlobalReportClasses", {
        all: true,
        search: text,
      });
    },
  },
};
</script>

<style scoped></style>
